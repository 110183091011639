@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Lato:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* UNIVERSAL STYLES */
html {
  font-size: calc(10px + 2vmin);
}

body {
  font-family: 'Lato', sans-serif;
  line-height: 1.35;
}

.App {
  text-align: center;
  background-color: #f1f3d5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-bottom: 180px; /* height of footer */
}

.normal-container {
  width: 60%;
}

.mobile-container {
  width: 90%;
}

.outline {
  height: 6px;
  width: 100%;
  background-color: #9cbebd;
}

.no-margins {
  margin: 0;
}

/* NAVBAR */
.navbar {
  width: 100%;
  background-color: #33312a;
  color: #9cbebd;
  font-size: 20px;
  text-align: center;
}

.mobile-navbar-link {
  padding-top: 34px;
  color: #9cbebd;
  float: right;
  height: 100px;
  width: 25%;
  text-decoration: none;
}

.navbar-link {
  padding-top: 24px;
  color: #9cbebd;
  float: right;
  height: 60px;
  width: 10%;
  text-decoration: none;
}

.navbar-link:hover, .mobile-navbar-link:hover {
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

.navbar-link-div {
  width: 100%;
  height: 100%;
}

.navbar-link:visited, .navbar-home-link-text:visited, .mobile-navbar-link:visited {
  color: #9cbebd;
}

.navbar-links {
  color: #9cbebd;
  margin-right: 20px;
}

.navbar-home-link-text {
  padding-top: 22px;
  color: #9cbebd;
  margin-left: 40px;
  float: left;
  font-size: 24px;
  text-decoration: none;
}

.mobile-navbar-home-link-text {
  padding-top: 6px;
  color: #9cbebd;
  margin-left: 18px;
  font-size: 24px;
  text-decoration: none;
  width: 100%;
  text-align: left;
}

.navbar-icon {
  height: 19px;
  margin-right: 8px;
}

.mobile-navbar-container {
  width: 100%;
  height: 100px;
  background-color: #33312a;
  position: fixed;
  bottom: 0;
}

.mobile-navbar-link {
  width: 100%;
  padding-top: 24px;
  color: #9cbebd;
  float: right;
  height: 60px;
  text-decoration: none;
  margin-left: 10px;
}

.mobile-navbar-link-div {
  width: 100%;
  height: 100%;
  margin-left: 2%;
}

/* PAGE TITLE */
.page-title-div {
  font-family: 'Lato', sans-serif;
  text-align: left;
  background-color: #d7dabd;
  width: 100%;
}

.home-page-contact-info {
  position: absolute;
  bottom: 160px;
  text-align: left;
  background-color: #d7dabd;
  width: 100%;
  margin: 0;
  font-size: 20px;
}

/* PROJECTS PAGE */
.projects-page {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
  padding-bottom: 20px;
  background-color: #9cbebd;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.accordion-section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background-color: #9cbebd;
  color: #2b2a2a;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover {
  background-color: #86a3a2;
}

.accordion-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
}

.accordion-icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion-content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}

.mobile-accordion-content {
  background-color: #33312a;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}

.accordion-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.project-card-image {
  width: 100%;
  float: left;
}

.project-card {
  width: 100%;
  background-color: pink;
}

/* ABOUT PAGE */
.about-page {
  font-size: 18px;
}

.about-page-body-text {
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: 'Lato', sans-serif;
  text-align: left;
}

/* RESUME PAGE */
.resume-section-container {
  padding: 2%;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 96%;
  background-color: #e2e4ce;
  text-align: left;
}

.resume-list {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
}

.resume-subheader {
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}

.resume-subsubheader {
  margin: 0;
  font-size: 18px;
}

.view-pdf-link {
  text-decoration: none;
}

.view-pdf-button {
  width: 200px;
  margin-bottom: 20px;
  background-color: #2e2d27;
  color: #9cbebd;
  border-radius: 10px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  font-size: 26px;
  border:none;
  cursor: pointer;
}

.resume-table {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
}

.resume-table-mobile-row {
  height: 35px;
}

.company-select {
  transition: width 1s, padding 1s, background-color 0.5s;
}

.company-select-not-selected:hover {
  cursor: pointer;
  background-color: #caccb6;
}

.company-select-not-selected {
  width: 20%;
  padding-top: 20%;
  height: 50%;
  border-radius: 50%;
  background-color: #d1d3bc;
  background-size: contain;
  margin: 1vmin;
}

.company-select-selected {
  width: 23%;
  padding-top: 23%;
  height: 50%;
  border-radius: 50%;
  background-size: contain;
  margin: 1vmin;
  background-color: #9cbebd;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  
}


/* ACHIEVEMENTS PAGE */
.achievements-item {
  margin-bottom: 5px;
}

/* FOOTER */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
 }

.footer-container {
  background-color: #33312a;
  color: lightgray;
  height: 140px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 20%;
  padding-left: 20%;
  padding-bottom: 20px;
}

.footer-text-container {
  margin-top: 20px;
}

.footer-icon {
  height: 40px;
}

/* HOME PAGE */
.home-page-button-link  {
  width: 70%; 
  text-decoration: none;
}

.home-page-button-div {
  border-radius: 10px;
  background-color: #9ea191;
  color: #2b2a2a;
  text-decoration: none
}
